import textE from "../../public/toudeuk/letter-e.json";
import textK from "../../public/toudeuk/letter-k.json";
import textO from "../../public/toudeuk/letter-o.json";
import textT from "../../public/toudeuk/letter-t.json";
import textD from "../../public/toudeuk/letter-d.json";
import textU from "../../public/toudeuk/letter-u.json";

export const CUSTOM_LETTER = {
  textE,
  textK,
  textO,
  textT,
  textD,
  textU,
};
